import EmblaCarousel from 'embla-carousel';
const emblaNode = document.getElementById('slideshow');

const emblaOptions = {
  loop: true
}

const lazyLoad = embla => {
  const slides = embla.slideNodes();
  const images = slides.map(slide => slide.querySelector(".embla__slide__img"));
  const imagesInView = [];

  const addImageLoadEvent = (image, callback) => {
    image.addEventListener("load", callback);
    return () => image.removeEventListener("load", callback);
  };

  const loadImagesInView = () => {
    embla
      .slidesInView(true)
      .filter(index => imagesInView.indexOf(index) === -1)
      .forEach(loadImageInView);
  };

  const loadImageInView = index => {
    const image = images[index];
    const slide = slides[index];
    imagesInView.push(index);
    image.src = image.getAttribute("data-src");

    const removeImageLoadEvent = addImageLoadEvent(image, () => {
      slide.classList.add("has-loaded");
      embla.reInit(emblaOptions);
      removeImageLoadEvent();
    });
  };

  return () => {
    loadImagesInView();
    return imagesInView.length === images.length;
  };
};


export const autoplay = (embla, interval) => {
  let timer = 0;

  const play = () => {
    stop();
    requestAnimationFrame(() => (timer = window.setTimeout(next, interval)));
  };

  const stop = () => {
    window.clearTimeout(timer);
    timer = 0;
  };

  const next = () => {
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
    play();
  };

  return { play, stop };
};

function appendImgToSlideshow(url) {
  const container = emblaNode.querySelector(".embla__container");

  const slide = document.createElement("div");
  slide.classList.add("embla__slide");

  const slideInner = document.createElement("div");
  slideInner.classList.add("embla__slide__inner");
  slide.appendChild(slideInner);

  const img = document.createElement("img");
  img.classList.add("embla__slide__img");
  img.setAttribute("data-src", url);
  img.setAttribute("alt", "");
  slideInner.appendChild(img);

  container.appendChild(slide);
}

import img_koppelnAlex from '../../static/img/slideshow/Koppeln_Axel_Leonard.jpeg';
import img_koppelnNorbert from '../../static/img/slideshow/Koppeln_Norbert Rad.jpeg';
import img_koppelnRobin from '../../static/img/slideshow/Koppeln_Robin Rad1.jpeg';
import img_leonhardZiel from '../../static/img/slideshow/Leonard Ziel.jpeg';
import img_lotharLauf from '../../static/img/slideshow/Lothar_Lauf Roth Zentrum.jpeg';
import img_lotharSolar from '../../static/img/slideshow/Lothar Solar.jpeg';
import img_axel from '../../static/img/slideshow/Mü-MA_Axel.jpeg';
import img_partyDielef from '../../static/img/slideshow/Party_Dielef_Lange_Elke.jpeg';
import img_elkeLaufen from '../../static/img/slideshow/elke-laufen.jpg';
import img_elkeRoth from '../../static/img/slideshow/elke-ziel-roth.jpeg';
import img_matteo from '../../static/img/slideshow/matteo.jpg';
import img_michaelLaufen from '../../static/img/slideshow/michael-laufen.jpeg';
import img_sabineLaufen from '../../static/img/slideshow/sabine-laufen.jpeg';
import img_neubiberg01 from '../../static/img/slideshow/neubiberg_1.jpg';
import img_neubiberg02 from '../../static/img/slideshow/neubiberg_2.jpg';
import img_neubiberg03 from '../../static/img/slideshow/neubiberg_3.jpg';
import img_istria01 from '../../static/img/slideshow/051_istria_01.jpg';
import img_rookies01 from '../../static/img/slideshow/rookies3_01.jpg';
import img_rookies02 from '../../static/img/slideshow/rookies3_04.jpg';
import img_rookies03 from '../../static/img/slideshow/rookies3_05.jpg';
import img_rookies04 from '../../static/img/slideshow/rookies3_06.jpg';
import img_slideshow00 from '../../static/img/slideshow/slideshow_6.jpg';
import img_slideshow01 from '../../static/img/slideshow/slideshow_8.jpg';
import img_slideshow02 from '../../static/img/slideshow/slideshow_9.jpg';
import img_slideshow03 from '../../static/img/slideshow/slideshow_12.jpg';
import img_slideshow04 from '../../static/img/slideshow/slideshow_13.jpg';
import img_slideshow05 from '../../static/img/slideshow/slideshow_15.jpg';


if(emblaNode) {
  appendImgToSlideshow(img_koppelnAlex);
  appendImgToSlideshow(img_koppelnNorbert);
  appendImgToSlideshow(img_koppelnRobin);
  appendImgToSlideshow(img_leonhardZiel);
  appendImgToSlideshow(img_lotharLauf);
  appendImgToSlideshow(img_lotharSolar);
  appendImgToSlideshow(img_axel);
  appendImgToSlideshow(img_partyDielef);
  appendImgToSlideshow(img_elkeLaufen);
  appendImgToSlideshow(img_elkeRoth);
  appendImgToSlideshow(img_matteo);
  appendImgToSlideshow(img_michaelLaufen);
  appendImgToSlideshow(img_sabineLaufen);
  appendImgToSlideshow(img_neubiberg01);
  appendImgToSlideshow(img_neubiberg02);
  appendImgToSlideshow(img_neubiberg03);
  appendImgToSlideshow(img_istria01);
  appendImgToSlideshow(img_rookies01);
  appendImgToSlideshow(img_rookies02);
  appendImgToSlideshow(img_rookies03);
  appendImgToSlideshow(img_rookies04);
  appendImgToSlideshow(img_slideshow00);
  appendImgToSlideshow(img_slideshow01);
  appendImgToSlideshow(img_slideshow02);
  appendImgToSlideshow(img_slideshow03);
  appendImgToSlideshow(img_slideshow04);
  appendImgToSlideshow(img_slideshow05);

  const embla = EmblaCarousel(emblaNode, emblaOptions);
  const loadImagesInView = lazyLoad(embla);
  const loadImagesInViewAndDestroyIfDone = eventName => {
    const loadedAll = loadImagesInView();
    if (loadedAll) embla.off(eventName, loadImagesInViewAndDestroyIfDone);
  };

  const autoplayer = autoplay(embla, 2000);

  embla.on("init", loadImagesInViewAndDestroyIfDone);
  embla.on("select", loadImagesInViewAndDestroyIfDone);
  embla.on("resize", loadImagesInViewAndDestroyIfDone);

  embla.on("pointerDown", autoplayer.stop);
  embla.on("init", autoplayer.play);

  window.embla = embla;
}
