var data = {}

const sendrow = $('.sendrow');

const nameInput = $('#contactform input[name="name"]');
const emailInput = $('#contactform input[name="email"]');
const subjectInput = $('#contactform input[name="subject"]');
const textInput = $('#contactform textarea[name="text"]');
const captchaInput = $('#contactform input[name="captcha"]');

function setError(message) {
  $('#contactbutton').removeClass('disabled');
  $('.confirmation').addClass('error');
  $('.confirmation').show();
  $('.confirmation span').html(message || 'Es ist ein Fehler beim Übermitteln der Nachricht aufgetreten.');
}

$('#contactbutton').on('click', (e) => {
  if($(this).hasClass('disabled'))
    return;

  data.name = nameInput.val();
  data.email = emailInput.val();
  data.subject = subjectInput.val();
  data.text = textInput.val();
  data.captcha = captchaInput.val();

  var errorString = "";

  if(data.name.length < 1 || data.name.length > 50) {
    errorString += "Der Name muss Zwischen 1 und 50 Zeichen lang sein.<br>";
  }

  if(data.email.length < 1 || !/.+@.+\..+/.test(data.email)) {
    errorString += "Bitte gebe eine gültige E-Mail Adresse an.<br>";
  }

  if(data.subject.length < 1 || data.subject.length > 200) {
    errorString += "Der Betreff muss Zwischen 1 und 200 Zeichen lang sein.<br>";
  }

  if(data.text.length < 1 || data.text.length > 5000) {
    errorString += "Die Nachricht muss Zwischen 1 und 5000 Zeichen lang sein.<br>";
  }

  if(data.captcha.length < 3 || data.text.length > 20) {
    errorString += "Die Spam-Schutz Frage muss richtig beantwortet werden.<br>";
  }

  if(errorString.length > 0) {
    $('.confirmation').addClass('error');
    $('.confirmation').show();
    $('.confirmation span').html(errorString);
    return;
  } else {
    $('.confirmation').removeClass('error');
    $('.confirmation').hide();
    $('.confirmation span').html('');
  }

  $(this).addClass('disabled');
  fetch("/contact", {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
  }).then((res) => {
    if(!res.ok) {
      if(res.status >= 400 && res.status < 500) {
        res.json().then(json => {
          setError(json.message);
        }).catch(e => {
          setError();
        });
      }
      setError();
    } else {
      $('.confirmation').addClass('success');
      $('.confirmation').show();
      $('.confirmation span').html('Deine Nachricht wurde erfolgreich übermittelt.');
      $('.sendrow').hide();
      nameInput.val('');
      emailInput.val('');
      subjectInput.val('');
      textInput.val('');
      captchaInput.val('');
    }
  }).catch((res) => {
    setError();
  });
});
