$('a[data-scroll]').on('click', function(event) {
  const id = $(this).attr('href');
  var offset;

  if(id == '#') {
    offset = 0;
    window.history.pushState(null, null, '/');
  } else {
    const target = $(id);
    if (target.length) {
      offset = target.offset().top - 68;
      window.history.pushState(null, null, id);
    } else {
      return;
    }
  }

  event.preventDefault();
  $('html, body').animate({
    scrollTop: offset
  }, 500);

});

const nav = $('nav');
const collapsable = $('.navbar-collapse');

// collapse navbar on click
$('nav a').on('click', (e) => {
  collapsable.collapse('hide');
});

$('.navbar-toggler').on('click', (e) => {
  collapsable.collapse('toggle');
});

collapsable.on('show.bs.collapse', (e) => {
  nav.addClass('collapsed');
});

collapsable.on('hide.bs.collapse', (e) => {
  resizeNav();
});

function resizeNav() {
  var scrollTop = $(window).scrollTop();
  if (scrollTop > 150) {
    nav.addClass('collapsed');
  } else {
    nav.removeClass('collapsed');
  }
}

if(nav.hasClass('animate')) {
  $(window).scroll(function() {
    resizeNav();
  });
}

if(collapsable && collapsable.length > 0) {
  resizeNav();
}
